import { render, staticRenderFns } from "./CardTemplateProduct.vue?vue&type=template&id=3551fc34&scoped=true"
import script from "./CardTemplateProduct.vue?vue&type=script&lang=js"
export * from "./CardTemplateProduct.vue?vue&type=script&lang=js"
import style0 from "./CardTemplateProduct.vue?vue&type=style&index=0&id=3551fc34&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3551fc34",
  null
  
)

export default component.exports