<template>
  <div class="card-template-product">
    <div class="template-product-content">
      <div class="template-image">
        <img
          :src="card.first_img ? card.first_img : dummyImage.bg"
          :alt="card.name"
          :style="imgStyle"
        />
        <div v-if="card.prod_type === 1" class="status status-9">임시저장</div>
        <div
          v-else-if="card.prod_type === 0 && card.enable_prod === false"
          class="status status-9"
        >
          비노출
        </div>
        <div v-else-if="card.prod_type === 0" class="status status-sale">
          전시중
        </div>
        <div v-else class="status" :class="`status-${card.request_status}`">
          {{ statusKor[card.request_status] }}
        </div>
      </div>
      <div class="template-info">
        <div class="template-categories">{{ card.template_categories }}</div>
        <div class="name">{{ card.name }}</div>
        <div class="price">{{ priceFormat(card.price) }}</div>
        <div class="flex-align" style="gap: 12px" v-if="card.prod_type === 0">
          <div class="flex-align" style="gap: 4px">
            <img src="/static/icon/u_star-primary.svg" style="width: 14px" />
            <div class="body6-bold main">
              {{ card.rate
              }}<span class="sub3"
                >({{ currencyNum(card.reviews_count) }})</span
              >
            </div>
          </div>
          <div class="flex-align" style="gap: 4px">
            <img
              src="/static/icon/u_eye.svg"
              class="svg-sub3"
              style="width: 14px"
            />
            <div class="body6 sub3">{{ currencyNum(card.visit) }}</div>
          </div>
        </div>
        <div class="tags">
          <div
            class="tag"
            v-for="(tag, index) in card.techs"
            :key="`tag-${index}`"
          >
            {{ tag }}
          </div>
        </div>
        <div class="reject pc" v-if="card.request_status === 2">
          <img src="/static/icon/u_info-circle-error.svg" />
          <div class="body5 error">{{ card.reject_reason }}</div>
        </div>
      </div>
    </div>
    <div class="reject mobile" v-if="card.request_status === 2">
      <img src="/static/icon/u_info-circle-error.svg" />
      <div class="body5 error">{{ card.reject_reason }}</div>
    </div>
    <div class="template-product-buttons">
      <button
        class="button button-update"
        :disabled="
          ![0, 2].includes(card.request_status) &&
          [2, 3].includes(card.prod_type)
        "
        @click="updateProduct"
      >
        수정
      </button>
      <button class="button" @click="deleteProduct">삭제</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardTemplateProduct",
  components: {},
  mixins: [],
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      statusKor: {
        0: "심사대기",
        1: "심사중",
        2: "심사반려",
        3: "심사반려",
        9: "임시저장",
      },
    };
  },
  computed: {
    imgStyle() {
      const [width, height] = this.imgRate(100);
      return {
        width: `${width}px`,
        height: `${height}px`,
        objectFit: "contain",
      };
    },
  },
  watch: {},
  mounted() {},
  methods: {
    priceFormat(price) {
      let result = this.currency(price.price);
      if (price.won_type === 1) {
        result += "~";
      } else if (price.won_type === 2) {
        result += " 초반대";
      } else if (price.won_type === 3) {
        result += " 후반대";
      }
      return result;
    },
    updateProduct() {
      if (this.card.prod_type === 1) {
        this.$router.push(`/template_create?temp_id=${this.card.id}`);
      } else {
        this.$router.push(
          `/template_create?id=${this.card.id}&request_status=${this.card.request_status}`
        );
      }
    },
    deleteProduct() {
      this.$emit("delete");
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.card-template-product
  background-color white
  border 1px solid $gray
  border-radius 8px
  display flex
  gap 16px
  .categories
    font-size 12px
    color $gray3
    margin-bottom 2px
  .name
    font-size 15px
    color $main
    margin-bottom 8px
  .price
    font-weight 700
    font-size 16px
    color $main
  .tags
    margin-top 8px
    display flex
    gap 8px
    flex-wrap wrap
    .tag
      border 1px solid $gray
      border-radius 50px
      padding 2px 8px
      color $sub
      font-size 12px

  .template-image
    border 1px solid $gray
    border-radius 8px
    overflow hidden
    position relative
    .status
      position absolute
      top 4px
      left 4px
      color $white
      font-size 12px
      padding 2px 6px
      color white
      &.status-sale
        background $info
      &.status-0
      &.status-1
        background $main
      &.status-2
      &.status-3
        background $error
      &.status-9
        background $gray2
        color $main
  .template-product-content
    padding 16px
    display flex
    align-items center
    gap 12px
    flex 1

  .template-info
    flex 1

  .template-product-buttons
    display flex
    justify-content center
    align-items center
    gap 8px
    padding 16px
    .button
      width 74px
      height 44px
      font-weight 500
      color $main
    .button-update
        border 1px solid $main
        &:disabled
          border 1px solid $gray
          color $sub5
          cursor not-allowed
  .reject
    margin-top 8px
    display flex
    align-items center
    gap 4px
    background-color #FFF0F0
    border-radius 8px
    padding 10px 12px
    &.pc
      display flex
    &.mobile
      display none

@media (max-width: 600px)
  .card-template-product
    flex-direction column
    gap 0
    .button
      flex 1
    .reject
      border-radius 0
      &.pc
        display none
      &.mobile
        display flex
    .template-product-buttons
      border-top 1px solid $gray
</style>
